import { providers } from 'ethers'
import { Staking_SDK } from 'ufo-sdk'

import { contracts } from '@/App'
import { showNotification } from '@/components/atoms/ToastNotifications'
interface Error {
  code: number | string
  message: string
}

export async function MultipleCollect(
  lockWeeks: string,
  depositNumbers: string[],
  tokenType: string,
  signer
) {  
  console.log("called multipleCollect");
  const instance = new Staking_SDK(
    signer,
    contracts,
    'https://gateway.thegraph.com/api/afb1023a6969388004b10016bc98f249/subgraphs/id/q3Uke8C9XxpgEfQpnke8ySbrtcJDeKF9UpbpoEeZi17'
  )

  if (tokenType == 'UFO') {
    try {
      const pool = await instance.getUfoPoolWithLockinWeeks(lockWeeks)
      await pool
        .withdrawVestedUfoFromMultipleDeposits(depositNumbers)
        .then(async (tx) => {
          await tx.wait(1).then(() => {
            showNotification.success('Transaction successful')
          })
        })
    } catch (error) {
      if ((error as Error).code === 4001) {
        showNotification.error('You closed Metamask ')
      } else if ((error as Error).code == 'UNPREDICTABLE_GAS_LIMIT') {
        showNotification.error('Impossible to perform transaction.')
      } else {
        console.log('error', error)

        showNotification.error('Something went wrong')
      }
    }
  } else {
    try {
      const pool = await instance.getLpPoolWithLockinWeeks(lockWeeks)
      await pool
        .withdrawVestedUfoFromMultipleDeposits(depositNumbers)
        .then(async (tx) => {
          await tx.wait(1).then(() => {
            showNotification.success('Transaction successful')
          })
        })
    } catch (error) {
      if ((error as Error).code === 4001) {
        showNotification.error('You closed Metamask ')
      } else if ((error as Error).code == 'UNPREDICTABLE_GAS_LIMIT') {
        showNotification.error('Impossible to perform transaction.')
      } else {
        console.log('error', error)

        showNotification.error('Something went wrong')
      }
    }
  }
}
