import { providers } from 'ethers'
import { Staking_SDK } from 'ufo-sdk'

import { contracts } from '@/App'
import { showNotification } from '@/components/atoms/ToastNotifications'

interface Error {
  code: number | string
  message: string
}

export async function PartialUnstakeFuction(
  lockWeeks: string,
  percent: string,
  tokenType: string,
  handleWaitingStop: () => void,
  signer: any,
  callback?: () => void,
) {
  console.log("called partialunstakefunction");
  const instance = new Staking_SDK(
    signer,
    contracts,
    'https://gateway.thegraph.com/api/afb1023a6969388004b10016bc98f249/subgraphs/id/q3Uke8C9XxpgEfQpnke8ySbrtcJDeKF9UpbpoEeZi17'
  )

  if (tokenType == 'UFO') {
    try {
      const pool = await instance.getUfoPoolWithLockinWeeks(lockWeeks)
      await pool.withdrawPartialDeposit(percent).then(() => {
        handleWaitingStop()
      })
      showNotification.success('Transaction successful')
      if (callback) {
        callback()
      }
    } catch (error) {
      if ((error as Error).code === 4001) {
        handleWaitingStop()
        showNotification.error('You closed Metamask ')
      } else if ((error as Error).code == 'UNPREDICTABLE_GAS_LIMIT') {
        handleWaitingStop()
        showNotification.error('Not enough gas balance')
      } else {
        console.log('error', error)
        handleWaitingStop()

        showNotification.error('Something went wrong')
      }
    }
  } else {
    try {
      const pool = await instance.getLpPoolWithLockinWeeks(lockWeeks)
      await pool.withdrawPartialDeposit(percent).then(() => {
        handleWaitingStop()
      })
      showNotification.success('Transaction successful')
    } catch (error) {
      if ((error as Error).code === 4001) {
        handleWaitingStop()
        showNotification.error('You closed Metamask ')
      } else if ((error as Error).code == 'UNPREDICTABLE_GAS_LIMIT') {
        handleWaitingStop()
        showNotification.error('Not enough gas balance')
      } else {
        handleWaitingStop()
        showNotification.error('Something went wrong')
      }
    }
  }
}
