import { Box, Heading, Text } from '@chakra-ui/react'
import { useRef, FC, useEffect, useState } from 'react'
import NumberFormat from 'react-number-format'

import { ClaimRewardApi } from './ClaimReward'
import { getPlasmaBalance } from '../pages/Markets/GetWalletBallance'
import { UnstakePopup } from '../popups/unstakePopup/UnstakePopup'

import { ReactComponent as UfoIcon } from '@/assets/img/UFO_icon.svg'
import Loader from '@/components/Loader'
import ButtonBase from '@/components/button'
import { CustomToolTip } from '@/components/tooltip'
import { getUnclaimedRewardsss } from '@/services'
import { formatToken } from '@/web3/helpers'

interface iProps {
  plasmaRewardAvailable: string
  ufoTokenRewardAvailable: string
  ufoTokenRewardAvailableInDollar: string
  setStakeModal: (toggle: boolean) => void
  setModalType: (type: number) => void
}

const UnclaimedRewards: FC<iProps> = (props) => {
  const {
    setStakeModal,
    setModalType,
    plasmaRewardAvailable,
    ufoTokenRewardAvailable,
    ufoTokenRewardAvailableInDollar,
  } = props
  const [plasmaBalance, setPlasmaBalance] = useState<string>()
  const [isRewards, setIsRewards] = useState<boolean>(false)
  const [openPopUp, setOpenPopUp] = useState<boolean>(false)
  const rewardsLoader = useRef<any | null>(null)
  const [showButtonBlock, setShowButtonBlock] = useState<boolean>(
    plasmaRewardAvailable === '0' && ufoTokenRewardAvailable === '0'
  )

  useEffect(() => {
    rewardsLoader.current && rewardsLoader?.current?.continuousStart()
    setShowButtonBlock(
      plasmaRewardAvailable === '0' && ufoTokenRewardAvailable === '0'
    )
  }, [plasmaRewardAvailable, ufoTokenRewardAvailable])

  // useEffect(() => {
  //   const test = async () => {
  //     const plasmaBalance = await getPlasmaBalance()
  //     if (parseInt(formatToken(plasmaBalance)) > 0) {
  //       setIsRewards(true)
  //     }
  //     setPlasmaBalance(formatToken(plasmaBalance))
  //   }
  //   test()
  // }, [])

  // function ClaimReward() {
  //   const test = async () => {
  //     await ClaimRewardApi()
  //   }
  //   test()
  // }

  return (
    <Box
      display="flex"
      alignItems="center"
      className="unclaimed-rewards"
      borderRadius="27px"
      // marginTop="180px"
      height="327px"
      flexDirection="column"
      padding="85px 0"
      pb={showButtonBlock ? '58px' : ''}
      textAlign="center"
      justifyContent="space-between"
    >
      <Heading color="white" fontSize="32px" fontWeight="800" lineHeight={1}>
        Total Rewards
      </Heading>

      {showButtonBlock ? (
        <>
          <Heading fontSize="24px" color="#D2FF59" fontWeight="800">
            Start Staking to Get Your Rewards
          </Heading>

          <CustomToolTip
            arrow
            // eslint-disable-next-line react/jsx-curly-brace-presence
            label={
              'Exciting Update Ahead! Staking is temporarily paused as we prepare to launch a fresh opportunity for you to stake and earn rewards. Stay tuned for details!'
            }
          >
            <div>
              <ButtonBase
                padding="25px 17px 25px 17px"
                width={{ base: '90%', sm: '252px' }}
                height="55px"
                background=" linear-gradient(180deg, #FA937D 0%, #8924F4 100%);"
                color="white"
                disabled
                onClick={() => {
                  setModalType(1)
                  setStakeModal(true)
                }}
              >
                Start staking
                <UfoIcon width={29} height={29} />
              </ButtonBase>
            </div>
          </CustomToolTip>
        </>
      ) : (
        <>
          {plasmaRewardAvailable && ufoTokenRewardAvailable ? (
            <>
              <Heading
                fontSize="24px"
                color="#D2FF59"
                fontWeight="800"
                width="90%"
              >
                UFO{' '}
                <NumberFormat
                  value={Number(ufoTokenRewardAvailable).toFixed(0)}
                  displayType="text"
                  thousandSeparator
                  renderText={(value) => <>{value}</>}
                />{' '}
                (${Number(ufoTokenRewardAvailableInDollar).toFixed(0)})
              </Heading>
              <Heading fontSize="24px" color="#5DFFCF;" width="90%">
                Plasma:{' '}
                <NumberFormat
                  value={Number(plasmaRewardAvailable).toFixed(0)}
                  displayType="text"
                  thousandSeparator
                  renderText={(value) => <>{value}</>}
                />
              </Heading>
            </>
          ) : (
            <Box sx={{ display: 'block', width: '50%' }}>
              <Loader ref={rewardsLoader} />
            </Box>
          )}
        </>
      )}
    </Box>
  )
}

export default UnclaimedRewards
