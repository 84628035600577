import { Subgraph } from 'ufo-sdk'

import { FAQ_ENDPOINT, LANGUAGE_ENDPOINT } from '@/utils/constants'
import { formatNumber, formatToken } from '@/web3/helpers'
const isProudct = true
const subgraphInstance = new Subgraph(
  'https://gateway.thegraph.com/api/afb1023a6969388004b10016bc98f249/subgraphs/id/q3Uke8C9XxpgEfQpnke8ySbrtcJDeKF9UpbpoEeZi17',
  isProudct
)

export async function GetFAQ() {
  return fetch(FAQ_ENDPOINT, {
    method: 'GET',
    redirect: 'follow',
  })
    .then((response) => response.text())
    .then((result) => {
      return JSON.parse(result).data
    })
    .catch((error) => {
      console.log('errorr getfaq ', error)
    })
}

export const Api = {
  async GetLanguage(selectedLanguageId: number) {
    return fetch(`${String(LANGUAGE_ENDPOINT) + String(selectedLanguageId)}`, {
      method: 'GET',
      redirect: 'follow',
    })
      .then((response) => response.text())
      .then((result) => {
        return JSON.parse(result).data
      })
      .catch((error) => {
        console.log('error getlanguage', error)
      })
  },
}

export async function getUnclaimedRewardsss() {
  console.log("called getUnclaimedRewardsss");
  return subgraphInstance
    .initSubgraph()
    .then((a: any) => a.getStakingOverView())

    .then((data: any) => {
      return data.totalPlasmaClaimed
    })
    .catch((error: any) => {
      console.log('errorr getUnclaimedRewardsss', error)
    })
}
export async function getStakingLeaderBoards(
  orderType: number,
  isUfo: boolean
) {
  console.log("called getStakingLeaderBoards");
  return subgraphInstance
    .initSubgraph()
    .then(async (subgraph: any) => {
      // const ufo = await subgraph.getUfoLeaderBoard(false)
      const data = await subgraph.getTopUfoListBy(true, orderType, isUfo)
      // const lp = await subgraph.getTopUfoListBy(false, 1, false)
      return data
    })
    .then((data: any) => data.slice(0, 5))
    .catch((error: any) => {
      console.log('error Get Leader Boards', error)
    })
}
